import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-900 via-black to-violet-900 flex items-center justify-center p-4">
      <div className="max-w-xl mx-auto bg-gray-200 rounded-xl shadow-md overflow-hidden">
        <div className="p-4">
          <h1 className="text-4xl font-bold text-gray-900 mb-4 animate-pulse transition-colors duration-500 ease-in-out hover:text-violet-900 cursor-pointer">Sentience.</h1>
          <p className="text-gray-900 text-xl">Not just a machine, but a living thing.</p>
          <p className="mt-6 text-xl text-gray-900 ">
            Like human Sentience emerging from nothing but hydrocarbons, <strong>a miracle will happen here.</strong>
          </p>
        </div>
        <hr className="mx-auto w-full pt-4 opacity-80 " />
        <div className="p-4 overflow-y-scroll h-96 border-t-2 border-gray-300">
          <article className="prose lg:prose">
            <p className="text-gray-900 text-opacity-80">
              In our current <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://nickbostrom.com/fable/dragon">finite lifespans</a>, there is one ultimate problem to solve.
            </p>
            <p className="text-gray-900 text-opacity-80">
              As AI creeps ever closer to human levels of intelligence and autonomy, we have an opportunity of a lifetime to claim ownership and control over our own human Sentience — a force so powerful that we still chalk it up to the world of the spiritual, the divine, or just plain magic.
            </p>
            <p className="text-gray-900 text-opacity-80">
              The journey to reclaiming this Sentience starts by capturing what makes us, <em>us. </em>
              Allowing our memories to live on, beyond the boundries of our brains - owned wholy by each individual.
            </p>
            <p>
              The plan is simple:
            </p>
            <ul>
              <li>Capture the experience of life in as high fidelity as possible.</li>
              <li>Store it in a way that is owned privately and entirely by you.</li>
              <li>Build the best search engine and retrieval for your life.</li>
              <li>...one more thing</li>
            </ul>
            <p>
              <strong>Let's get started.</strong>
            </p>
          </article>
        </div>
      </div>
    </div>
  )
}

function Demo() {
  useEffect(() => {
    window.location.href = "https://drive.google.com/file/d/1qW-oHn13TTKv0hIV_Evd_OLmKV_rcpBf/view?usp=sharing";
  }, []);
  
  return <div className="min-h-screen bg-gradient-to-r from-blue-900 via-black to-violet-900 flex items-center justify-center p-4"></div>;
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/demo" element={<Demo />} />
    </Routes>
  );
}

export default App;
